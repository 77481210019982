class Count {
  constructor(id) {
    this.elements = document.querySelectorAll(id);

    if(this.elements.length) {
      this.initCount();
    }
  }

  initCount() {
    this.duration = 2000;
    this.objects = [];

    this.elements.forEach((element) => {
      this.objects.push({
        el: element,
        options: {
          init: false,
          start: 0,
          end: parseInt(element.getAttribute('data-count')),
          duration: this.duration
        }
      });
    });

    this.initCountUp();

    window.addEventListener('scroll', function() {
      this.initCountUp()
    }.bind(this));

    window.addEventListener('resize', function() {
      this.initCountUp()
    }.bind(this));
  }

  initCountUp() {
    this.objects.forEach(obj => {
      if(this.isInViewport(obj.el) && !obj.options.init) {
        obj.options.init = true;

        this.animateValue(obj.el, obj.options.start, obj.options.end, obj.options.duration);
      }
    });
  }

  animateValue(element, start, end, duration) {
    var obj = element;
    var range = end - start;
    var minTimer = 50;
    var stepTime = Math.abs(Math.floor(duration / range));
    stepTime = Math.max(stepTime, minTimer);
    var startTime = new Date().getTime();
    var endTime = startTime + duration;
    var timer;
  
    function run() {
        var now = new Date().getTime();
        var remaining = Math.max((endTime - now) / duration, 0);
        var value = Math.round(end - (remaining * range));
        obj.innerHTML = value;
        if (value == end) {
            clearInterval(timer);
        }
    }
    
    timer = setInterval(run, stepTime);
    run();
  }

  isInViewport(element) {
    var bounding = element.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
}