class GoogleMap {
  constructor(id) {
    this.elements = document.querySelectorAll(id);

    if(this.elements.length) {
      this.initMap();
    }
  }

  initMap() {
	var i;
    for(i=0; i < this.elements.length; i++) {
	    this.initGoogleMap(this.elements[i]);
    }
  }

  initGoogleMap(element) {
    var mapStyle = [
      {
        "featureType": "poi",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ];

    var mapCenter = {
      "lat": 51.10422,
      "lng": 3.75772
    };

    var map = new google.maps.Map(element, {
      center: mapCenter,
      zoom: 16,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      styles: mapStyle
    });

    this.addPolylines(map);

    this.addMarkers(map);
  }

  addPolylines(map) {
    var dashedLine = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 2
    };

    var jsonPoly1 = [
      {
        "lat": 51.10684,
        "lng": 3.76051
      },
      {
        "lat": 51.10675,
        "lng": 3.76044
      },
      {
        "lat": 51.10657,
        "lng": 3.7603
      },
      {
        "lat": 51.10644,
        "lng": 3.76011
      },
      {
        "lat": 51.10638,
        "lng": 3.76003
      },
      {
        "lat": 51.10606,
        "lng": 3.75974
      },
      {
        "lat": 51.10554,
        "lng": 3.75939
      },
      {
        "lat": 51.10496,
        "lng": 3.75903
      },
      {
        "lat": 51.10477,
        "lng": 3.75891
      },
      {
        "lat": 51.1047,
        "lng": 3.75888
      },
      {
        "lat": 51.10466,
        "lng": 3.75885
      },
      {
        "lat": 51.10463,
        "lng": 3.75881
      },
      {
        "lat": 51.1046,
        "lng": 3.75877
      },
      {
        "lat": 51.10458,
        "lng": 3.75874
      },
      {
        "lat": 51.10457,
        "lng": 3.75871
      },
      {
        "lat": 51.10456,
        "lng": 3.7587
      },
      {
        "lat": 51.10456,
        "lng": 3.75868
      },
      {
        "lat": 51.10455,
        "lng": 3.75867
      },
      {
        "lat": 51.10455,
        "lng": 3.75865
      },
      {
        "lat": 51.10455,
        "lng": 3.75862
      },
      {
        "lat": 51.10454,
        "lng": 3.7586
      },
      {
        "lat": 51.10453,
        "lng": 3.75854
      }
    ]
    var jsonPoly2 = [
      {
        "lat": 51.1010755,
        "lng": 3.7574879
      },
      {
        "lat": 51.101226,
        "lng": 3.7575439
      },
      {
        "lat": 51.1013252,
        "lng": 3.7575815
      },
      {
        "lat": 51.1014136,
        "lng": 3.757614
      },
      {
        "lat": 51.101541,
        "lng": 3.7576656
      },
      {
        "lat": 51.1016248,
        "lng": 3.7577005
      },
      {
        "lat": 51.1016831,
        "lng": 3.7577243
      },
      {
        "lat": 51.1016957,
        "lng": 3.7577444
      },
      {
        "lat": 51.1017448,
        "lng": 3.7578068
      },
      {
        "lat": 51.1018105,
        "lng": 3.7578876
      },
      {
        "lat": 51.1019132,
        "lng": 3.7579533
      },
      {
        "lat": 51.1019435,
        "lng": 3.7579694
      },
      {
        "lat": 51.1021025,
        "lng": 3.7580462
      },
      {
        "lat": 51.1022865,
        "lng": 3.7581303
      },
      {
        "lat": 51.102384,
        "lng": 3.7581746
      },
      {
        "lat": 51.1025627,
        "lng": 3.7582738
      },
      {
        "lat": 51.1026309,
        "lng": 3.7583114
      },
      {
        "lat": 51.102797,
        "lng": 3.7583898
      },
      {
        "lat": 51.1028968,
        "lng": 3.7584371
      },
      {
        "lat": 51.1029918,
        "lng": 3.7585001
      },
      {
        "lat": 51.103093,
        "lng": 3.7585689
      },
      {
        "lat": 51.1032817,
        "lng": 3.7587194
      },
      {
        "lat": 51.1034017,
        "lng": 3.7588106
      },
      {
        "lat": 51.1035505,
        "lng": 3.7589665
      },
      {
        "lat": 51.103752,
        "lng": 3.7591563
      },
      {
        "lat": 51.103789,
        "lng": 3.7592032
      },
      {
        "lat": 51.1040459,
        "lng": 3.7595244
      },
      {
        "lat": 51.1040351,
        "lng": 3.7595519
      },
      {
        "lat": 51.1040252,
        "lng": 3.7595965
      },
      {
        "lat": 51.1040187,
        "lng": 3.7596431
      },
      {
        "lat": 51.1040164,
        "lng": 3.7596826
      },
      {
        "lat": 51.104017,
        "lng": 3.7597242
      },
      {
        "lat": 51.1040196,
        "lng": 3.7597631
      },
      {
        "lat": 51.1040259,
        "lng": 3.7598007
      },
      {
        "lat": 51.1040351,
        "lng": 3.7598402
      },
      {
        "lat": 51.1040425,
        "lng": 3.7598674
      },
      {
        "lat": 51.1040518,
        "lng": 3.7598915
      },
      {
        "lat": 51.1040636,
        "lng": 3.7599177
      },
      {
        "lat": 51.1040777,
        "lng": 3.7599448
      },
      {
        "lat": 51.1040962,
        "lng": 3.7599713
      },
      {
        "lat": 51.1041162,
        "lng": 3.7599961
      },
      {
        "lat": 51.1041332,
        "lng": 3.7600132
      },
      {
        "lat": 51.1041463,
        "lng": 3.760024
      },
      {
        "lat": 51.1041608,
        "lng": 3.7600343
      },
      {
        "lat": 51.104177,
        "lng": 3.7600431
      },
      {
        "lat": 51.1041945,
        "lng": 3.7600498
      },
      {
        "lat": 51.104205,
        "lng": 3.7600538
      },
      {
        "lat": 51.1042259,
        "lng": 3.7600578
      },
      {
        "lat": 51.1042444,
        "lng": 3.7600581
      },
      {
        "lat": 51.1042646,
        "lng": 3.7600568
      },
      {
        "lat": 51.1042812,
        "lng": 3.7600524
      },
      {
        "lat": 51.1042974,
        "lng": 3.7600471
      },
      {
        "lat": 51.1043134,
        "lng": 3.760039
      },
      {
        "lat": 51.104328,
        "lng": 3.76003
      },
      {
        "lat": 51.1043435,
        "lng": 3.7600189
      },
      {
        "lat": 51.1043625,
        "lng": 3.7600035
      },
      {
        "lat": 51.1043817,
        "lng": 3.7599814
      },
      {
        "lat": 51.104397,
        "lng": 3.7599606
      },
      {
        "lat": 51.1044099,
        "lng": 3.7599398
      },
      {
        "lat": 51.1044267,
        "lng": 3.7599059
      },
      {
        "lat": 51.1044398,
        "lng": 3.7598717
      },
      {
        "lat": 51.1044507,
        "lng": 3.7598385
      },
      {
        "lat": 51.1044587,
        "lng": 3.7598067
      },
      {
        "lat": 51.104464,
        "lng": 3.7597852
      },
      {
        "lat": 51.1044672,
        "lng": 3.7597341
      },
      {
        "lat": 51.1044681,
        "lng": 3.7597026
      },
      {
        "lat": 51.1044681,
        "lng": 3.7596764
      },
      {
        "lat": 51.1044666,
        "lng": 3.7596453
      },
      {
        "lat": 51.1044616,
        "lng": 3.7596081
      },
      {
        "lat": 51.1044552,
        "lng": 3.7595755
      },
      {
        "lat": 51.1044485,
        "lng": 3.7595477
      },
      {
        "lat": 51.1044416,
        "lng": 3.7595259
      },
      {
        "lat": 51.1044346,
        "lng": 3.7595081
      },
      {
        "lat": 51.1044258,
        "lng": 3.7594867
      },
      {
        "lat": 51.1044165,
        "lng": 3.7594679
      },
      {
        "lat": 51.1044043,
        "lng": 3.7594465
      },
      {
        "lat": 51.1043931,
        "lng": 3.7594283
      },
      {
        "lat": 51.1043786,
        "lng": 3.7594089
      },
      {
        "lat": 51.1043639,
        "lng": 3.7593941
      },
      {
        "lat": 51.1043523,
        "lng": 3.7593834
      },
      {
        "lat": 51.1043359,
        "lng": 3.75937
      },
      {
        "lat": 51.1043256,
        "lng": 3.759363
      },
      {
        "lat": 51.1043816,
        "lng": 3.7590823
      },
      {
        "lat": 51.104446,
        "lng": 3.758847
      },
      {
        "lat": 51.1045277,
        "lng": 3.7585509
      },
      {
        "lat": 51.1046308,
        "lng": 3.7581781
      },
      {
        "lat": 51.1046874,
        "lng": 3.757932
      },
      {
        "lat": 51.1047245,
        "lng": 3.7577731
      },
      {
        "lat": 51.104859,
        "lng": 3.7571984
      },
      {
        "lat": 51.1049148,
        "lng": 3.756957
      },
      {
        "lat": 51.1049653,
        "lng": 3.7567401
      },
      {
        "lat": 51.1050617,
        "lng": 3.7563267
      },
      {
        "lat": 51.1050723,
        "lng": 3.7563166
      },
      {
        "lat": 51.1051024,
        "lng": 3.7562811
      },
      {
        "lat": 51.1051478,
        "lng": 3.7562362
      },
      {
        "lat": 51.105194,
        "lng": 3.7562016
      },
      {
        "lat": 51.1052072,
        "lng": 3.7561929
      },
      {
        "lat": 51.1052643,
        "lng": 3.7561695
      },
      {
        "lat": 51.1052971,
        "lng": 3.7561782
      },
      {
        "lat": 51.1053352,
        "lng": 3.7561976
      },
      {
        "lat": 51.1053769,
        "lng": 3.7562392
      },
      {
        "lat": 51.1053902,
        "lng": 3.7562563
      },
      {
        "lat": 51.1054299,
        "lng": 3.7563777
      },
      {
        "lat": 51.1054493,
        "lng": 3.7565155
      },
      {
        "lat": 51.105371,
        "lng": 3.756844
      },
      {
        "lat": 51.1053487,
        "lng": 3.7569376
      },
      {
        "lat": 51.1053207,
        "lng": 3.7570576
      },
      {
        "lat": 51.105275,
        "lng": 3.7572507
      },
      {
        "lat": 51.1052506,
        "lng": 3.757355
      },
      {
        "lat": 51.1051582,
        "lng": 3.7578432
      },
      {
        "lat": 51.1051491,
        "lng": 3.7579773
      },
      {
        "lat": 51.1051523,
        "lng": 3.7580708
      },
      {
        "lat": 51.1051476,
        "lng": 3.7580772
      },
      {
        "lat": 51.1051175,
        "lng": 3.7581248
      },
      {
        "lat": 51.1050365,
        "lng": 3.7582633
      },
      {
        "lat": 51.1049521,
        "lng": 3.7584034
      },
      {
        "lat": 51.1048363,
        "lng": 3.7584467
      },
      {
        "lat": 51.1047192,
        "lng": 3.7584202
      },
      {
        "lat": 51.1046666,
        "lng": 3.7583917
      },
      {
        "lat": 51.1045645,
        "lng": 3.758335
      },
      {
        "lat": 51.104454,
        "lng": 3.7582757
      },
      {
        "lat": 51.1044136,
        "lng": 3.7582294
      },
      {
        "lat": 51.104314,
        "lng": 3.7581104
      },
      {
        "lat": 51.1042683,
        "lng": 3.7579789
      },
      {
        "lat": 51.1042165,
        "lng": 3.7576759
      }
    ];

    var pathPoly1 = new google.maps.Polyline({
      path: jsonPoly1,
      geodesic: true,
      strokeColor: '#007BCD',
      strokeOpacity: 0,
      strokeWeight: 4,
      icons: [{
        icon: dashedLine,
        offset: '0',
        repeat: '12px'
      }],
    });

    var pathPoly2 = new google.maps.Polyline({
      path: jsonPoly2,
      geodesic: true,
      strokeColor: '#007BCD',
      strokeOpacity: 0,
      strokeWeight: 4,
      icons: [{
        icon: dashedLine,
        offset: '0',
        repeat: '12px'
      }],
    });

    pathPoly1.setMap(map);
    pathPoly2.setMap(map);
  }

  addMarkers(map) {
    var volvo = {
      url: '/templates/default/dist/img/volvo.png',
      scaledSize: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0,0),
      anchor: new google.maps.Point(25,25)
    };
    var receptie = {
      url: '/templates/default/dist/img/receptie.png',
      scaledSize: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0,0),
      anchor: new google.maps.Point(25,25)
    };

    var marker = {
      url: '/templates/default/dist/img/marker.png',
      scaledSize: new google.maps.Size(30, 30),
      origin: new google.maps.Point(0,0),
      anchor: new google.maps.Point(15,15)
    };

    new google.maps.Marker({
      position: {
        "lat": 51.10422,
        "lng": 3.75772
      },
      map: map,
      title: 'Volvo Car Gent',
      icon: volvo
    });

    new google.maps.Marker({
      position: {
        "lat": 51.1030885,
        "lng": 3.7552462
      },
      map: map,
      title: 'Receptie',
      icon: receptie
    });

    new google.maps.Marker({
      position: {
        "lat": 51.10684,
        "lng": 3.76051
      },
      map: map,
      title: 'Komende van de E34',
      label: {
        text: 'A',
        color: 'white'
      },
      icon: marker
    });

    new google.maps.Marker({
      position: {
        "lat": 51.1010755,
        "lng": 3.7574879
      },
      map: map,
      title: 'Komende van de E17 of E40',
      label: {
        text: 'B',
        color: 'white'
      },
      icon: marker
    });
  }
}