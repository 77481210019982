class Hero {
  constructor(id) {
    this.elements = document.querySelectorAll(id);

    if(this.elements.length) {
      this.initHero();
    }
  }

  initHero() {
	var i;
    for(i=0; i < this.elements.length; i++) {
	    this.initSwipe(this.elements[i]);
    }
  }

  initSwipe(element) {
    var slideshow = new Swipe(element, {
      continuous: true,
      speed: 800,
      auto: 10000,
      autoRestart: true,
      draggable: true,
      callback: function(index) {
        this.updateControls(element, index);
      }.bind(this)
    });

    var controls = element.querySelector('.hero__controls');

    for(var i = 0; i < slideshow.getNumSlides(); i++) {
      var button = this.createSwipeControl(slideshow, i);
      controls.append(button);
    }
  }

  createSwipeControl(slideshow, index) {
    var button = document.createElement('button');
        button.onclick = () => {
          slideshow.slide(index, 800);
        }

        if(index == 0){
          button.className = 'is-active';
        }
    
    return button;
  }

  updateControls(element, index) {
    var controls = element.querySelector('.hero__controls');
        controls.querySelector('button.is-active').classList.remove('is-active');
        controls.querySelectorAll('button')[index].classList.add('is-active');
  }
}