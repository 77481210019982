class Progress {
  constructor(id) {
    this.elements = document.querySelectorAll(id);

    if(this.elements.length) {
      this.initProgress();
    }
  }

  initProgress() {
	var i;
    for(i=0; i < this.elements.length; i++) {
	    this.initProgressOnScroll(this.elements[i]);
    }
  }

  initProgressOnScroll(element) {
    var track = element.querySelector('div');
    
    this.updateScrollPosition(track);

    window.addEventListener('scroll', function() {
      this.updateScrollPosition(track);
    }.bind(this));
  }

  updateScrollPosition(track) {
    var scrolled = this.AmountScrolled();
    track.style.width = scrolled + '%';
  }

  AmountScrolled(){
    var winheight= window.innerHeight || (document.documentElement || document.body).clientHeight
    var docheight = this.getDocumentHeight()
    var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    var trackLength = docheight - winheight
    return Math.floor(scrollTop/trackLength * 100);
  }

  getDocumentHeight() {
    var d = document;
    return Math.max(
      d.body.scrollHeight, d.documentElement.scrollHeight,
      d.body.offsetHeight, d.documentElement.offsetHeight,
      d.body.clientHeight, d.documentElement.clientHeight
    );
  }
}

new Progress('[data-progress]');