class Toggle {
  constructor(id) {
    this.elements = document.querySelectorAll(id);
    
    if(this.elements.length) {
      this.initToggle();
    }
  }

  initToggle() {
	var i;
    for(i=0; i < this.elements.length; i++) {
	    this.addListener(this.elements[i]);
    }
  }

  addListener(element) {
    var target = document.querySelector('[data-toggle-id="'+element.getAttribute('data-toggle')+'"]');

    element.onclick = () => {
      this.toggleClass(element, target, 'is-active', false);
      return false;
    }
  }

  toggleClass(element, target, className, overflow = true) {
    if(!element.classList.contains(className)) {
      element.classList.add(className);
      target.classList.add(className);

      if(!overflow) {
        document.querySelector('body').style.overflow = 'hidden';
      }
    } else {
      element.classList.remove(className);
      target.classList.remove(className);

      if(!overflow) {
        document.querySelector('body').style.overflow = null;
      }
    }
  }
}